import React from "react"
import { Link, graphql } from "gatsby"
import GImg from "gatsby-image"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"
import {
  snap,
  banner,
  charityImgPath,
  charityMoustaches,
  allCharities,
} from "../utils/imageUpload"
import BodyWrapper from "../components/BodyWrapper"

const SocialMission = ({ data }) => {
  // console.log("data:", data)

  const [banner2] = data.allFile.edges.map(({ node }) => node)
  return (
    <Layout>
      <SEO title="Social Mission " />
      <BodyWrapper bg="var(--pink)" always>
        <Landing>
          <GImg fluid={banner2.childImageSharp.fluid} />
        </Landing>
      </BodyWrapper>

      <BodyWrapper bg={"var(--beige)"}>
        <Container>
          <SnapContainer>
            <ChildContainer>
              <h1 className="caps">It's Easy as 1,2,3. </h1>
              <ul>
                <li>1 - SNAP A PICTURE ROCKING A MINI COOKIE STASH.</li>

                <li>2 - POST TO SOCIAL MEDIA</li>
                <li>
                  3 - TAG US @BELGIANBOYS, YOUR FAVORITE CHARITY, AND
                  #ROCKTHATSTASH
                </li>
              </ul>
              <hr />
              <p>
                Each month since the launch of our Mini Cookie Stash in 2017, we
                have given a portion of the proceeds from those products to one
                of our customer's favorite charities. We have supported small
                local charities and some of the largest in the world working on
                more causes than we can count - all thanks to the nominations
                using #RockThatStash!
              </p>
            </ChildContainer>
            <div className="snap">
              <img src={snap} alt="" />
            </div>
          </SnapContainer>

          <CharityDonations>
            <h1>YOUR FAVORITE CHARITY COULD BE NEXT!</h1>
            <div className="grid">
              {charityMoustaches.map((el, i) => (
                <Img key={i} main={el.pic} hover={el.hover} />
              ))}
            </div>
          </CharityDonations>

          <CharityContainer>
            <h1>SINCE LAUNCH WE HAVE GIVEN MONTHLY TO CHARITIES INCLUDING:</h1>
            <div className="charityGrid">
              {allCharities.map((el, i) => (
                <img src={el} key={i} alt="" />
              ))}
            </div>
          </CharityContainer>
          <Link to="/shop">
            <button>Shop mini cookie stash</button>
          </Link>
        </Container>
      </BodyWrapper>
    </Layout>
  )
}

export default SocialMission

const Img = styled.div`
  background-image: url('${props => props.main}');
  background-size:cover;
  background-repeat: no-repeat;
  padding-bottom: 150%;
  :hover {
  background-image: url('${props => props.hover}');
  background-size: contain;
  background-position:center;
  }
`

const Landing = styled.div`
  background-color: var(--pink);
  min-height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
  img {
    width: 80%;
    object-fit: fill;
  }
  @media (max-width: 769px) {
    min-height: 30vh;
    .gatsby-image-wrapper {
      width: 80%;
    }
  }

  @media (min-width: 1500px) {
    min-height: 50vh;
  }
`

const CharityContainer = styled.section`
  width: 60vw;
  margin: 0 auto;
  /* margin: 0 auto 5rem; */
  @media (min-width: 1500px) {
    width: 100%;
  }
  h1 {
    margin-bottom: 3rem;
  }

  .charityGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    grid-gap: 30px;
    img {
      width: 100%;
    }
  }

  @media (max-width: 769px) {
    width: 90vw;
  }
`

const CharityDonations = styled.section`
  margin: 5rem auto 10rem;
  width: 60vw;
  h1 {
    font-size: 50px;
    margin-bottom: 3rem;
  }

  @media (min-width: 1500px) {
    width: 100%;
  }

  .grid {
    display: grid;
    grid-gap: 30px;
    width: 70%;
    margin: 0 auto;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));

    .img {
      width: 100%;
      img {
        /* object-fit: scale-down; */
        width: 100%;
      }
      .noHover {
        :hover {
          display: none;
        }
      }
      .hover {
        display: none;
        &:hover {
          display: inherit;
        }
      }
    }
  }

  @media (max-width: 769px) {
    width: 95vw;
    .grid {
      grid-template-columns: repeat(auto-fit, minmax(120px, 100px));
    }
  }

  @media (max-width: 374px) {
    .grid {
      justify-content: center;
    }
  }
`

const ChildContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 20px;
  color: var(--darkBlue);

  .caps {
    text-transform: uppercase;
  }
  ul {
    list-style-type: none;
    li {
      padding-top: 2rem;
      font-weight: bold;
      line-height: 150%;
    }
  }
  hr {
    margin: 2rem 0;
  }
  p {
    text-align: justify;
  }

  @media (max-width: 1200px) {
    font-size: 20px;
    ul {
      li {
        padding-top: 1rem;
      }
    }
  }

  @media (max-width: 870px) {
    hr {
      margin: 1.25rem 0;
    }
  }
`

const SnapContainer = styled.main`
  text-align: initial;
  width: 80vw;
  padding: 5rem 0 5rem;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  grid-gap: 3rem;
  .snap {
    display: flex;
    justify-content: center;
    height: 450px;
    img {
      height: 100%;
    }
  }

  @media (min-width: 1500px) {
    width: 100%;
  }

  @media (max-width: 870px) {
    grid-template-columns: 1.25fr 1fr;
    width: 70vw;
  }

  @media (max-width: 769px) {
    grid-template-columns: 1fr;
  }
  /* display: grid;
  grid-template-columns: 1.5fr 1fr;
  column-gap: 5rem;
  width: 70vw;
  color: var(--darkBlue);
  justify-content: space-between;
  padding: 5rem;

  .snap {
    width: 100%;
    img {
      width: 100%;
    }
  }
  @media (max-width: 1250px) {
    width: 65vw;
    column-gap: 3rem;
    grid-template-columns: 1fr 3fr;
  } */

  @media (max-width: 769px) {
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    align-items: center;
    .snap {
      margin-top: 50px;
      img {
        height: 100%;
      }
    }
  }

  @media (max-width: 374px) {
    img {
      width: 100%;
    }
  }
`

const Container = styled.div`
  background-color: var(--beige);
  min-height: 100vh;
  text-align: center;
  .snap-container {
  }
  h1 {
    color: var(--pink);
    font-size: 40px;
  }

  button {
    margin-top: 40px;
    padding: 20px 30px;
    background-color: var(--darkBlue);
    width: 300px;
    color: white;
    border-radius: 25px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1.2em;
    border: none;
    margin-bottom: 5rem;

    :hover {
      background-color: var(--pink);
    }
  }

  @media (max-width: 769px) {
    h1 {
      text-align: center;
    }
  }
`

export const query = graphql`
  query DreamsPage {
    allFile(
      filter: {
        sourceInstanceName: { eq: "pagebanners" }
        relativeDirectory: { eq: "dreams" }
      }
    ) {
      edges {
        node {
          name
          childImageSharp {
            fluid {
              src
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
